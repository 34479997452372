import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <>
      <title>Sorry!</title>
      <header className="m-4">
        <h1 className="text-7xl">
          <span role="img" aria-label="cry emoji">
            😢
          </span>{" "}
          Sorry!
        </h1>
      </header>
      <main className="m-4">
        <p>
          <span role="img" aria-label="shrugging person emoji">
            🤷
          </span>{" "}
          Not found!
        </p>
      </main>
    </>
  );
};

export default NotFoundPage;
